export class LoadPatients {
  static readonly type = '[SearchStatus] Load Patients';

  constructor(
    public readonly patientIds: MsApp.Guid[],
    public readonly tcId?: MsApp.Guid
  ) {}
}

export class ClearPatients {
  static readonly type = '[SearchStatus] Clear Patients';
}
